/* Adjust the main container width for more space */
.admin-dashboard-container {
  margin-top: 80px;
  padding: 20px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.admin-dashboard-title {
  color: #333;
  font-size: 2rem;
  margin-bottom: 30px;
}

/* Ensure user items use the available space effectively */
.user-item {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.user-info {
  font-size: 1.1rem;
  color: #555;
}

.user-actions {
  display: flex;
  align-items: center;
}

.update-message {
  margin-top: 20px;
  color: green;
  font-weight: bold;
}

.user-role-select,
.form-select,
.form-control {
  max-width: 300px;
}

.text-center {
  text-align: center;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.me-2 {
  margin-right: 0.5rem;
}
