/* PartnerSupporters.css */

/* Partners and Supporters container */
.partners-supporters-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the content */
  gap: 20px; /* Consistent spacing */
  padding: 20px;
}

/* Individual partner/supporter styling */
.partner-supporter {
  flex: 1 1 150px; /* Responsive sizing */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.partner-supporter img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

/* Hover effects for interactivity */
.partner-supporter:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Specific styling for Beneto Foundation logo */
.beneto-logo {
  max-height: 56px; /* Ensures consistency with provided image height */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .partners-supporters-container {
    flex-direction: column;
    align-items: center;
  }

  .partner-supporter {
    flex: 1 1 100%;
  }
}
