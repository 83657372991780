.footer {
  background-color: #f0f0f0;
  padding: 20px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  position: relative;
  padding-bottom: 40px;
}

.footer div {
  flex: 1;
  padding: 10px;
  min-width: 180px;
}

.footer-contact,
.footer-social,
.footer-subscribe {
  text-align: center;
}

.social-icon {
  margin: 0 5px;
  color: #333;
  font-size: 24px;
}

.footer-subscribe form {
  display: flex;
  flex-direction: column;
}

.footer-subscribe input {
  padding: 5px;
  margin-bottom: 10px;
}

.footer-subscribe button {
  padding: 5px;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
}

.footer-subscribe .seal-image-container {
  text-align: center;
  margin-top: 20px;
}

.footer-subscribe .seal-image-container img {
  max-width: 100px;
  height: auto;
}

.website-credit {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.8em;
  color: #000; /* Black color for the text */
}

.credit-name {
  color: #d4af37; /* Gold color for the name */
  text-decoration: none;
}

.seal-image-container {
  text-align: center;
}

.seal-image {
  max-width: 100px;
  margin-top: 20px;
}

@media (max-width: 600px) {
  .footer {
    flex-direction: column;
  }
}
