/* Container Styling */
.class-lists-container {
  margin-top: 80px;
  padding: 20px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.class-lists-title {
  text-align: center;
  margin-bottom: 30px;
}

.class-lists-controls {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.class-lists-controls .form-control {
  max-width: 300px;
}

.month-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
}

.month-title {
  margin: 0;
  font-size: 1.5rem;
  margin-top: 10px;
}

.event-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.event-info {
  font-size: 1.1rem;
}

.no-events {
  text-align: center;
  font-size: 1.2rem;
  color: #6c757d;
  margin-top: 20px;
}

.alert {
  text-align: center;
}

/* Responsive Styling */
@media (max-width: 576px) {
  .event-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .event-item .btn {
    margin-top: 10px;
    width: 100%;
  }

  .class-lists-controls {
    flex-direction: column;
  }

  .month-navigation {
    flex-direction: column;
  }

  .month-navigation .btn {
    width: 100%;
    margin-bottom: 10px;
  }
}
