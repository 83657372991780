/* Invoicing.css */

/* ---------------------------------------
  **Container Styling**
--------------------------------------- */
.invoicing-container {
  max-width: 900px;
  margin: 0 auto;
}

/* ---------------------------------------
  **User Invoices Styling**
--------------------------------------- */
.user-invoices h3 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.user-invoices .list-group-item {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* ---------------------------------------
  **Form Group Styling**
--------------------------------------- */
.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.form-group label {
  width: 150px;
  margin-right: 10px;
  font-weight: bold;
}

.form-group input,
.form-group select,
.form-group textarea {
  flex: 1;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
}

/* ---------------------------------------
  **Button Styling**
--------------------------------------- */
button.btn {
  margin-top: 10px;
}

.btn-success,
.btn-primary,
.btn-danger,
.btn-secondary {
  margin-right: 10px;
}

/* ---------------------------------------
  **User Invoices List Styling**
--------------------------------------- */
.user-invoices ul {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
}

.user-invoices li {
  margin-bottom: 20px;
}

.list-group-item-action:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

/* ---------------------------------------
  **Users List Styling**
--------------------------------------- */

/* **Users List Container** */
.user-list {
  /* Optional: Add any specific styles for the user-list container if needed */
}

/* **Users List Header** */
.user-list h3 {
  margin-bottom: 15px;
  font-weight: 600;
  color: #333;
}

/* **Search Bar Styling** */
.user-list .form-control {
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
}

/* **Users List Card** */
.user-list .card {
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

/* **Users List Items** */
.user-list .list-group-item {
  transition: background-color 0.3s ease, border-left 0.3s ease,
    box-shadow 0.3s ease;
  border: none; /* Remove default borders */
  border-bottom: 1px solid #eee;
  padding: 15px 20px;
  position: relative;
}

.user-list .list-group-item:last-child {
  border-bottom: none; /* Remove border from the last item */
}

.user-list .list-group-item:hover {
  background-color: #f8f9fa; /* Light gray on hover */
  box-shadow: inset 5px 0 0 #007bff; /* Blue inset shadow on hover */
}

/* **Selected User Styling** */
.selected-user {
  background-color: #d1ecf1 !important; /* Light blue background */
  border-left: 5px solid #17a2b8 !important; /* Teal left border */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.selected-user span {
  font-weight: 600; /* Bolder text for emphasis */
  color: #0c5460; /* Dark teal text */
}

/* **Badge Styling for Selected User** */
.badge-primary {
  background-color: #17a2b8;
  color: #fff;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

/* **No Users Found Message** */
.user-list .list-group-item.text-center.text-muted {
  background-color: transparent;
  border: none;
  color: #6c757d;
  padding: 15px 20px;
}

/* ---------------------------------------
  **Pagination Styling for Available Events**
--------------------------------------- */
.available-events-pagination .pagination {
  margin-top: 20px; /* Spacing above pagination */
}

.available-events-pagination .page-item.active .page-link {
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.available-events-pagination .page-link {
  color: #17a2b8;
}

.available-events-pagination .page-link:hover {
  color: #117a8b;
}

/* ---------------------------------------
  **Confirmation Dialog Styling**
--------------------------------------- */
.confirmation-dialog {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* ---------------------------------------
  **Alert Styling**
--------------------------------------- */
.alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}

/* ---------------------------------------
  **Additional Styling for Selected User Badge**
--------------------------------------- */
.user-list .badge {
  font-size: 0.8rem;
  padding: 0.3em 0.6em;
}

/* ---------------------------------------
  **Accessibility Enhancements**
--------------------------------------- */
.selected-user,
.list-group-item {
  outline: none;
}

.selected-user:focus,
.list-group-item:focus {
  box-shadow: 0 0 0 3px rgba(23, 162, 184, 0.5);
}
