/* BoardOfDirector.css */

/* Board members container */
.board-members-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px; /* Increased gap for better spacing */
  padding: 20px;
}

/* Individual board member styling */
.board-member {
  flex: 1 1 180px; /* Adjusted for better responsiveness */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: auto; /* Removed fixed height for flexibility */
  transition: transform 0.3s, box-shadow 0.3s; /* Added transition for hover effects */
}

.board-member img {
  width: 150px; /* Reduced size for better alignment */
  height: 150px; /* Reduced height to match width */
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #007bff; /* Added border for emphasis */
  margin-bottom: 10px;
  transition: transform 0.3s; /* Added transition for hover effects */
}

.board-member:hover img {
  transform: scale(1.05); /* Slight zoom on hover */
}

.board-member-name {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.25em; /* Adjusted font size */
  color: #333333; /* Darker color for better readability */
  margin-top: 10px;
  line-height: 1.2em;
}

.board-member-title {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1em; /* Increased font size for clarity */
  color: #555555; /* Softer color for titles */
  margin-top: 5px;
  line-height: 1.3em;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .board-members-container {
    flex-direction: column;
    align-items: center;
  }

  .board-member {
    flex: 1 1 100%;
  }

  .board-member img {
    width: 120px;
    height: 120px;
  }

  .board-member-name {
    font-size: 1.1em;
  }

  .board-member-title {
    font-size: 0.95em;
  }
}
