@import url("https://fonts.googleapis.com/css2?family=Patua+One&family=Fira+Sans:wght@300;400&display=swap");

.dynamic-section {
  margin-top: 60px;
  margin-bottom: 60px;
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;
}

.dynamic-section h2 {
  max-width: 600px;
  margin: 20px auto;
  font-size: 2em;
  line-height: 1.3em;
  font-family: "Patua One", cursive;
  font-weight: 500;
}

.dynamic-section .headline-lines {
  width: 40%;
  height: 2px;
  background: #272b2d;
  margin: 20px auto;
}

.dynamic-section p {
  font-family: "Fira Sans", sans-serif;
  font-weight: 300;
  font-size: 1.857em;
  line-height: 1.5em;
  margin: 30px 0;
  color: #272b2d;
}
