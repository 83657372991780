/* src/components/HeroSection/HeroSection.css */

/* Base Hero Section Styling */
.hero-section {
  width: 100%;
  min-height: 60vh; /* Replaced fixed height with min-height using viewport units */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center;
  text-align: center;
  padding: 20px 0; /* Added vertical padding */
  background-color: #000; /* Fallback background color */
}

/* Hero Image Styling */
.hero-image {
  width: 100%;
  height: auto;
  max-height: 600px; /* Increased max-height for larger screens */
  object-fit: cover; /* Ensures the image covers the container without distortion */
  display: block; /* Removes any inline spacing */
}

/* Hero Text Styling */
.hero-section .hero-text {
  color: #fff;
  position: absolute;
  top: 100px;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
}

.hero-section h1 {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 3em;
  line-height: 1.42857143;
  margin: 0;
}

.hero-section p {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.5em;
  line-height: 1.42857143;
}

/* Smooth Scrolling */
html {
  scroll-behavior: smooth;
}

/* Responsive Styling */

/* Large Screens (Desktops and Extra-Large Devices) */
@media (min-width: 1200px) {
  .hero-section {
    min-height: 80vh; /* Increased height for better spacing */
  }

  .hero-image {
    max-height: 700px; /* Further increased max-height for larger screens */
  }

  .hero-section .hero-text {
    top: 120px; /* Adjust top positioning for larger screens */
    width: 60%; /* Reduced width for better aesthetics */
    padding: 25px; /* Increased padding */
  }

  .hero-section h1 {
    font-size: 3.5em; /* Slightly larger font */
  }

  .hero-section p {
    font-size: 1.6em; /* Slightly larger font */
  }
}

/* Medium Screens (Tablets and Small Desktops) */
@media (max-width: 1199.98px) and (min-width: 768px) {
  .hero-section {
    min-height: 70vh; /* Adjusted min-height */
  }

  .hero-image {
    max-height: 600px; /* Adjusted max-height */
  }

  .hero-section .hero-text {
    top: 100px; /* Maintain top positioning */
    width: 70%; /* Adjust width */
    padding: 20px; /* Maintain padding */
  }

  .hero-section h1 {
    font-size: 3em; /* Maintain font size */
  }

  .hero-section p {
    font-size: 1.5em; /* Maintain font size */
  }
}

/* Small Screens (Large Phones and Small Tablets) */
@media (max-width: 767.98px) and (min-width: 576px) {
  .hero-section {
    min-height: 60vh; /* Adjusted min-height */
  }

  .hero-image {
    max-height: 500px; /* Reduced max-height */
  }

  .hero-section .hero-text {
    top: 80px; /* Adjust top positioning */
    width: 75%; /* Adjust width */
    padding: 15px; /* Reduce padding */
  }

  .hero-section h1 {
    font-size: 2.5em; /* Reduced font size */
  }

  .hero-section p {
    font-size: 1.3em; /* Reduced font size */
  }
}

/* Extra Small Screens (Mobile Phones) */
@media (max-width: 575.98px) {
  .hero-section {
    min-height: 50vh; /* Reduced height for mobile */
  }

  .hero-image {
    max-height: 300px; /* Further limit image height on mobile */
    object-fit: cover; /* Ensure image covers the container */
  }

  .hero-section .hero-text {
    position: static; /* Remove absolute positioning */
    transform: none; /* Remove transform */
    width: 90%; /* Increase width for better fit */
    background: rgba(
      0,
      0,
      0,
      0.7
    ); /* Increase background opacity for readability */
    margin-top: 20px; /* Add top margin to separate from image */
    padding: 10px; /* Reduce padding */
  }

  .hero-section h1 {
    font-size: 1.8em; /* Further reduced font size */
  }

  .hero-section p {
    font-size: 1em; /* Further reduced font size */
  }
}
