.register-container {
  margin-top: 200px;
  margin-bottom: 200px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #fff;
}

.register-container h1 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.register-container form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.register-container input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.register-container button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.register-container button:hover {
  background-color: #0056b3;
}

.register-container p {
  text-align: center;
  margin-top: 20px;
  color: #555;
}
.register-wrapper {
  padding-top: 120px; /* Adjust based on nav bar height */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.back-to-login-button {
  margin-bottom: -120px; /* Space between the button and the form */
}

.register-container {
  width: 100%;
  max-width: 400px; /* Adjust based on preference */
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional styling */
  border-radius: 5px; /* Optional styling */
  background-color: #fff; /* Optional styling */
}
