/* src/components/UserProfile/UserProfile.css */

/* General Container Styling */
.user-profile {
  position: relative;
  max-width: 100%; /* Full width for better responsiveness */
  margin: 20px auto 50px; /* Reduced top margin for smaller screens */
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Header Styling */
.user-profile h1 {
  color: #333;
  font-size: 24px; /* Reduced font size for better mobile fit */
  margin-bottom: 20px;
  text-align: center;
}

/* User Details and Other Sections */
.user-details,
.disclaimer,
.form-status-section {
  margin-bottom: 20px;
}

.user-details p,
.disclaimer p,
.form-status {
  font-size: 16px; /* Adjusted for readability on mobile */
  margin-bottom: 8px;
}

/* Disclaimer Styling */
.disclaimer {
  background-color: #ffecb3;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Form Status Styling */
.form-status-section h2 {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
}

.form-status {
  font-size: 18px;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.form-status.completed {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.form-status.not-completed {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

/* Add to Calendar Button */
.user-profile .add-to-calendar-button {
  display: inline-block;
  width: auto;
  padding: 8px 12px; /* Adjusted padding for better touch size */
  margin-left: 10px;
  font-size: 14px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-self: center;
}

.user-profile .add-to-calendar-button:hover {
  background-color: #c82333;
}

/* Cancel Class Button */
.user-profile .cancel-class-button {
  display: inline-block;
  padding: 8px 12px;
  margin-left: 10px;
  font-size: 14px;
  background-color: #ffc107; /* Amber color for visibility */
  color: #212529; /* Dark text for contrast */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-self: center;
}

.user-profile .cancel-class-button:hover {
  background-color: #e0a800;
}

/* Calendar Options Dropdown */
.calendar-options-dropdown {
  position: absolute;
  background: #fff;
  border: 1px solid #ccc;
  padding: 8px;
  z-index: 10;
  width: 150px; /* Fixed width for consistency */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  text-align: center;
}

.calendar-options-dropdown a {
  display: block;
  margin: 5px 0;
  color: #007bff;
  text-decoration: none;
}

.calendar-options-dropdown a:hover {
  text-decoration: underline;
}

/* Class Item Styling */
.class-item {
  position: relative; /* For positioning the dropdown */
  margin-bottom: 15px;
  padding: 15px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.class-title {
  font-weight: 700; /* Increased font weight */
  font-size: 18px; /* Increased font size */
  color: #333;
  margin-bottom: 5px;
}

.class-time {
  color: #17a2b8;
  font-size: 16px; /* Increased font size */
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.class-time button {
  margin-left: 10px;
  margin-top: 5px;
}

/* Quick Links Styling */
.quick-links {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  justify-content: center; /* Center items */
  gap: 10px; /* Space between items */
  border: 1px solid #ddd;
  padding: 10px 20px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.quick-links span {
  font-weight: bold;
  margin-right: 10px;
}

.quick-links button {
  padding: 6px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.quick-links button.logout-button {
  background-color: #dc3545; /* Red color for logout */
}

.quick-links button:hover {
  opacity: 0.9;
}

/* Buttons in User Profile */
.user-profile button {
  display: block;
  width: 100%; /* Full width on mobile */
  padding: 10px;
  margin: 10px 0;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.user-profile button.btn-primary {
  background-color: #007bff;
}

.user-profile button.btn-info {
  background-color: #17a2b8;
}

.user-profile button.btn-success {
  background-color: #28a745;
}

.user-profile button.btn-danger {
  background-color: #dc3545;
}

.user-profile button.btn-secondary {
  background-color: #6c757d;
}

.user-profile button:hover {
  opacity: 0.9;
}

/* Alternative Options Section */
.alternative-options-section {
  margin-top: 20px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.alternative-options-section p {
  margin-bottom: 10px;
  color: #333;
}

.alternative-options-section .btn-primary,
.alternative-options-section .btn-info {
  margin: 5px;
  border: none;
  font-weight: bold;
}

/* Responsive Typography and Layout Adjustments */
@media (max-width: 768px) {
  /* Adjust header font size */
  .user-profile h1 {
    font-size: 22px;
  }

  /* Adjust user details font size */
  .user-details p,
  .disclaimer p,
  .form-status {
    font-size: 15px;
  }

  /* Adjust class title and time font sizes */
  .class-title {
    font-size: 16px;
  }

  .class-time {
    font-size: 14px;
  }

  /* Adjust add-to-calendar and cancel buttons */
  .user-profile .add-to-calendar-button,
  .user-profile .cancel-class-button {
    padding: 7px 11px;
    font-size: 13px;
  }

  /* Adjust quick links font size */
  .quick-links span {
    font-size: 14px;
  }

  .quick-links button {
    font-size: 13px;
    padding: 5px 10px;
  }

  /* Adjust alternative options buttons */
  .alternative-options-section .btn-primary,
  .alternative-options-section .btn-info {
    font-size: 14px;
    padding: 8px 12px;
  }
}

@media (max-width: 576px) {
  /* Further reduce header font size for very small screens */
  .user-profile h1 {
    font-size: 20px;
  }

  /* Further adjust user details font size */
  .user-details p,
  .disclaimer p,
  .form-status {
    font-size: 14px;
  }

  /* Further adjust class title and time font sizes */
  .class-title {
    font-size: 14px;
  }

  .class-time {
    font-size: 12px;
  }

  /* Further adjust add-to-calendar and cancel buttons */
  .user-profile .add-to-calendar-button,
  .user-profile .cancel-class-button {
    padding: 6px 10px;
    font-size: 12px;
  }

  /* Adjust quick links font size and layout */
  .quick-links span {
    font-size: 13px;
  }

  .quick-links button {
    font-size: 12px;
    padding: 4px 8px;
  }

  /* Adjust alternative options buttons */
  .alternative-options-section .btn-primary,
  .alternative-options-section .btn-info {
    font-size: 13px;
    padding: 7px 10px;
  }

  /* Ensure class items don't overflow */
  .class-time {
    flex-direction: column;
    align-items: flex-start;
  }

  .class-time button {
    margin-left: 0;
    margin-top: 5px;
  }
}

/* Interest Promotion Section Styling */
.interest-promotion {
  margin-top: 30px;
  text-align: center;
}

.interest-promotion-heading {
  font-size: 18px;
  margin-bottom: 20px;
  color: #333;
  font-weight: bold;
}

/* Class Options Layout Using Bootstrap Grid */
.class-options {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 20px;
}

.today-classes,
.tomorrow-classes {
  flex: 1;
  padding: 10px;
}

.today-classes,
.tomorrow-classes {
  width: 100%; /* Full width on small screens */
  margin-bottom: 20px;
}

.today-classes h3,
.tomorrow-classes h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.today-classes ul,
.tomorrow-classes ul {
  list-style: none;
  padding: 0;
}

.today-classes li,
.tomorrow-classes li {
  margin-bottom: 10px;
  font-size: 16px;
}

/* Vertical Line (Hidden on Small Screens) */
.vertical-line {
  display: none; /* Hide vertical line on small screens */
}

/* Staff and Admin Features Sections */
.staff-features-section,
.admin-features-section {
  margin-top: 20px;
}

.staff-features-section h3,
.admin-features-section h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.staff-features-section button,
.admin-features-section button {
  margin-bottom: 10px;
}

/* Additional Responsive Adjustments */
@media (min-width: 768px) {
  /* Show vertical line between classes sections on medium and larger screens */
  .vertical-line {
    display: block;
    height: auto;
    width: 1px;
    background-color: #ddd;
    margin: 0 20px;
  }

  .today-classes,
  .tomorrow-classes {
    width: 48%; /* Adjust width to allow space for vertical line */
  }
}

/* Role Highlight Styling */
.role-highlight {
  font-weight: bold;
  color: #007bff;
  background-color: #f8f9fa;
  padding: 5px;
  border-radius: 5px;
  display: inline-block;
}

/* Disclaimer Section */
.disclaimer {
  background-color: #ffecb3;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Miscellaneous Adjustments */
.admin-dashboard-spacing {
  margin-bottom: 120px !important;
}

.text-center {
  text-align: center;
}

.d-flex {
  display: flex;
  justify-content: center; /* Centering the buttons horizontally */
}

.mr-2 {
  margin-right: 8px; /* Adds spacing to the right of the first button */
}

.mt-4,
.mt-3 {
  margin-top: 16px; /* Adds margin to the top for spacing */
}

.mb-2,
.mb-3,
.mb-4,
.mb-5 {
  margin-bottom: 8px; /* Adds margin to the bottom for spacing */
}

/* Message Alerts Styling */
.alert {
  padding: 10px 15px;
  border-radius: 4px;
  margin-bottom: 15px;
  font-size: 16px;
}

.alert-success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.alert-danger {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

/* Adjustments for Calendar Options Dropdown Positioning */
.class-item .calendar-options-dropdown {
  top: 40px; /* Position dropdown below the buttons */
  right: 10px; /* Align to the right */
}

/* Styling for Add to Cart Button within Class List Items */
.class-list-item .add-to-cart-button {
  background-color: #28a745; /* Green color */
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
}

.class-list-item .add-to-cart-button:hover {
  background-color: #218838;
}

/* Enhanced Styling for Class Lists */
.class-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.class-list-item .class-title-text {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
}

.class-list-item .class-time-text {
  font-size: 1rem;
  color: #666;
}

.class-list-item .add-to-cart-button,
.class-list-item .cancel-class-button {
  margin-left: 10px;
}

/* Calendar Options Dropdown Enhancements */
.calendar-options-dropdown {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 1000;
}

/* Responsive Adjustments for Dropdown */
@media (max-width: 768px) {
  .calendar-options-dropdown {
    position: static;
    margin-top: 10px;
  }
}
