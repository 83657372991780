/* src/components/DonationSection/DonationSection.css */

.donation-section {
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers child elements horizontally */
}

.donation-section h2 {
  font-family: "Patua One", cursive;
  font-size: 2em;
  margin-bottom: 30px;
}

.donation-section p {
  font-family: "Fira Sans", sans-serif;
  font-weight: 300;
  font-size: 1.5em;
  margin-bottom: 40px;
  color: #272b2d;
}

.donorbox-form {
  margin: 0 auto;
  /* width: 100%; */
  max-width: 500px; /* Adjusted to maintain proportion */
  display: flex;
  justify-content: center;
  align-items: center;
}

.donorbox-form iframe {
  width: 100%;
  height: 520px;
  border: none;
  display: block;
  margin: 0 auto; /* Ensures iframe is centered within flex container */
}

/* Style for the button in the paragraph */
.donation-section button {
  color: #fff;
  background: #272b2d;
  padding: 10px 16px;
  font-family: "Fira Sans", sans-serif;
  font-size: 1em;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
  border-radius: 0;
}

.donation-section button:hover {
  background-color: #1f2426;
}

/* Responsive Styling */

/* Large Screens (Desktops and Extra-Large Devices) */
@media (min-width: 1200px) {
  .donation-section {
    padding: 40px 20px;
  }

  .donation-section h2 {
    font-size: 2.5em;
    margin-bottom: 40px;
  }

  .donation-section p {
    font-size: 1.6em;
    margin-bottom: 50px;
    color: #272b2d;
  }

  /* .donorbox-form {
    max-width: 600px; 
  } */

  .donorbox-form iframe {
    height: 600px; /* Increased height for better visibility */
  }
}

/* Medium Screens (Tablets and Small Desktops) */
@media (max-width: 1199.98px) and (min-width: 768px) {
  .donation-section h2 {
    font-size: 2.2em;
    margin-bottom: 35px;
  }

  .donation-section p {
    font-size: 1.3em;
    margin-bottom: 35px;
  }

  .donorbox-form {
    max-width: 450px; /* Adjusted max-width */
  }

  .donorbox-form iframe {
    height: 500px; /* Adjusted height */
  }
}

/* Small Screens (Large Phones and Small Tablets) */
@media (max-width: 767.98px) and (min-width: 576px) {
  .donation-section {
    padding: 30px 15px;
  }

  .donation-section h2 {
    font-size: 2em;
    margin-bottom: 30px;
  }

  .donation-section p {
    font-size: 1.2em;
    margin-bottom: 30px;
  }

  .donorbox-form {
    max-width: 400px; /* Adjusted max-width */
  }

  .donorbox-form iframe {
    height: 450px; /* Adjusted height */
  }

  /* Adjust button size */
  .donation-section button {
    padding: 8px 14px;
    font-size: 0.9em;
  }
}

/* Extra Small Screens (Mobile Phones) */
@media (max-width: 575.98px) {
  .donation-section {
    padding: 20px 10px;
  }

  .donation-section h2 {
    font-size: 1.8em;
    margin-bottom: 25px;
  }

  .donation-section p {
    font-size: 1em;
    margin-bottom: 25px;
  }

  .donorbox-form {
    max-width: 100%; /* Full width on mobile */
  }

  .donorbox-form iframe {
    height: 400px; /* Reduced height for mobile */
  }

  /* Ensure iframe fits within the container */
  .donorbox-form iframe {
    width: 100%;
    max-width: 100%;
  }

  /* Adjust button size */
  .donation-section button {
    padding: 6px 12px;
    font-size: 0.8em;
  }
}
