/* src/components/EditClasses/EditClasses.css */

.container {
  max-width: 1200px;
}

.table th,
.table td {
  vertical-align: middle;
}

thead.thead-dark th {
  background-color: #343a40;
  color: #ffffff;
}

.btn-warning {
  background-color: #ffc107;
  border: none;
  color: #212529;
}
.table th,
.table td {
  vertical-align: middle;
  /* You can adjust text alignment, padding, etc. */
}

.thead-light th {
  background-color: #f8f9fa;
}

.btn-warning {
  margin-right: 5px;
}
.semi-gray {
  background-color: #d3d3d3; /* light gray */
  opacity: 0.8;
}
