/* StripeCheckoutPage.css */
.checkout-form {
  max-width: 500px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 100px;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#card-element {
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.btn-block {
  width: 100%;
  padding: 10px;
  font-size: 18px;
}
#cardholder-name {
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  margin-bottom: 10px;
}
