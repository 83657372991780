@import url("https://fonts.googleapis.com/css2?family=Patua+One&family=Fira+Sans:wght@300;400&display=swap");

.mission-section {
  margin-top: 400px;
  margin-bottom: 60px;
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;
}

.headline-container h2 {
  max-width: 600px;
  margin: 20px auto;
  font-size: 2em;
  line-height: 1.3em;
  font-family: "Patua One", cursive;
  font-weight: 500;
}

.headline-lines {
  width: 40%;
  height: 2px;
  background: #272b2d;
  margin: 20px auto;
}

.mission-section p {
  font-family: "Fira Sans", sans-serif;
  font-weight: 300;
  font-size: 1.857em;
  line-height: 1.5em;
  margin: 30px 0;
  color: #272b2d;
}
.read-more-button {
  display: block;
  margin-right: auto;
  margin-left: auto;
  width: 200px;
  color: #fff;
  background: #272b2d;
  padding: 20px 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
  font-family: "Fira Sans", sans-serif;
  font-size: 1em;
  border-radius: 0px;
  text-align: center;
  margin-top: 40px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.read-more-button:hover {
  background-color: #1f2426;
}
html {
  scroll-behavior: smooth;
}

.features-section {
  margin-top: 40px;
  padding: 20px;
  background-color: #f4f4f4;
  text-align: center;
}

.features-section .headline-container h2 {
  font-size: 2em;
  font-family: "Patua One", cursive;
}

.features-description {
  font-family: "Fira Sans", sans-serif;
  font-weight: 300;
  font-size: 1.857em;
  line-height: 1.5em;
  color: #272b2d;
}

.features-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.features-button {
  color: #fff;
  background: #272b2d;
  padding: 10px 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
  font-family: "Fira Sans", sans-serif;
  font-size: 1em;
  border-radius: 0px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.features-button:hover {
  background-color: #1f2426;
}
