.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
/* Modal.css */

.participant-count {
  margin-top: 5px;
}

.participant-count.warning {
  color: orange;
}

.participant-count.full {
  color: red;
}

.no-space-message {
  color: red;
  font-weight: bold;
}

.date-item {
  margin-bottom: 15px;
}

/* Add any additional styling as needed */

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
  width: 50%;
  max-height: 70vh;
  overflow-y: auto;
  position: relative;
  text-align: center;
}

.modal-image {
  display: block;
  max-width: 90%;
  height: auto;
  margin: 20px auto;
}

.date-selection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.date-button {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  cursor: pointer;
}

.date-button.selected {
  background-color: #bde0fe;
  border-color: #89cff0;
}

.modal-close,
.add-to-cart,
.add-to-calendar {
  padding: 10px 20px;
  margin-top: 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #ff6961;
  color: white;
}

.add-to-cart {
  background-color: #77dd77;
}

.add-to-calendar {
  background-color: #dc3545;
  margin-bottom: 15px;
}

.modal-close-x {
  position: absolute;
  top: -15px;
  right: -2px;
  border: none;
  margin-top: 10px;
  margin-right: 10px;
  background: transparent;
  font-size: 30px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .modal-content {
    width: 80%;
  }
  .modal-image {
    max-width: 100%;
  }
}
