.donation-section {
  position: relative;
  text-align: center;
  margin-bottom: 30px;
}

.donation-bg-image {
  width: 100%;
  max-height: 500px;
  overflow: hidden;
}

.donation-bg-image img {
  width: 100%;
  height: auto;
  display: block;
}

.donation-impact {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  width: 100%;
}

.donation-impact li {
  background: rgba(0, 0, 0, 0);
  color: white;
  padding: 20px 10px;
  margin: 0 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.donation-impact li::before {
  content: attr(data-amount);
  background-color: black;
  color: white;
  border-radius: 50%;
  padding: 10px;
  margin-bottom: 5px;
  font-weight: bold;
}
@media (max-width: 768px) {
  .donation-bg-image {
    max-height: 300px;
  }

  .donation-impact li {
    padding: 10px 5px;
    margin: 5px;
    font-size: 0.9rem;
  }

  .donation-impact li::before {
    padding: 8px;
    font-size: 0.8rem;
  }

  .donation-impact {
    bottom: 5%;
    left: 5%;
    transform: translateX(-5%);
    width: 90%;
  }
}
