/* .services-title {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  margin: 0.67em 0;
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 10px;
  font-family: "Patua One", cursive;
  font-size: 3.429em;
  color: #040303;
  padding: 5px 0px 0px 20px;
  float: left;
  transition: all 0.25s ease-in-out;
  margin-top: 60px;
  margin-left: 15px !important;
  bottom: 15px;
  position: relative;
}

.services-section {
  margin-top: 60px;
  margin-bottom: 60px;
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;
}
.our-services-section {
  padding-top: 200px;
}

.headline-container h2 {
  max-width: 600px;
  margin: 20px auto;
  font-size: 2em;
  line-height: 1.3em;
  font-family: "Patua One", cursive;
  font-weight: 500;
}

.headline-lines {
  width: 40%;
  height: 2px;
  background: #272b2d;
  margin: 20px auto;
}

.services-section p {
  font-family: "Fira Sans", sans-serif;
  font-weight: 300;
  font-size: 1.857em;
  line-height: 1.5em;
  margin: 30px 0;
  color: #272b2d;
}

.services-section-photo-container {
  width: 100%;
  margin: 30px auto;
  display: flex;
  justify-content: center;
}

.services-section-image {
  width: 100%;
  height: auto;
  max-width: 600px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
html {
  scroll-behavior: smooth;
} */
.back-to-profile-top,
.back-to-profile-bottom {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.back-to-profile-top {
  margin-top: 80px;
}
.headline-container {
  margin-top: 120px;
}
