.policies-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  /* padding-top: 80px; */
}

.policies-container h1 {
  text-align: center;
  margin-bottom: 40px;
}

.policies-container section {
  margin-bottom: 30px;
  text-align: center;
}

.policies-container h2 {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

.policies-container a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #0066cc;
  font-weight: bold;
  text-align: center;
}

.policies-container a:hover {
  text-decoration: underline;
}

.policies-container img {
  width: 24px;
  height: auto;
  margin-right: 10px;
  text-align: center;
}
.back-to-profile-top,
.back-to-profile-bottom {
  display: flex;
  justify-content: center; /* Center the button horizontally */
}

.back-to-profile-top {
  margin-top: 80px; /* Adjust based on your navbar height */
}

.back-to-profile-bottom {
  margin-bottom: 80px; /* Adjust based on desired spacing at the bottom */
}
