.class-scheduler {
  margin: 80px auto;
  padding: 20px;
  border-radius: 8px;
  background-color: #f3f3f3;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.class-scheduler h1 {
  text-align: center;
  margin-bottom: 20px;
}

.class-scheduler-form-container {
  max-width: 600px;
  margin: 0 auto 20px;
  padding: 20px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.class-scheduler-form-container form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.class-scheduler-form-container input,
.class-scheduler-form-container textarea {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.class-scheduler-form-container button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  justify-content: center !important;
  align-items: center !important;
}

.class-scheduler-form-container button:hover {
  background-color: #0056b3;
}
.color-picker {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.color-option {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  cursor: pointer;
  border: 1px solid #ddd;
}

.color-option.selected {
  border: 2px solid black;
}
.rbc-event {
  color: black !important;
}
.recurring-section {
  margin-top: 10px;
}

.disclaimer {
  margin-bottom: 10px;
  font-size: 0.9em;
  color: #666;
}
.recurring-date-picker .calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.calendar-cell {
  height: 30px;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
}

.calendar-cell.empty {
  background: transparent;
  cursor: default;
}

.calendar-cell.selected {
  background: #a0e0a0;
  color: white;
}

.calendar-header-cell {
  text-align: center;
  font-weight: bold;
  padding: 5px;
  background: #eaeaea;
  border-radius: 4px;
}
.recurring-date-picker {
  font-family: Arial, sans-serif;
  text-align: center;
}

.calendar-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.control-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.control-button:hover {
  background-color: #0056b3;
}

.month-label {
  font-size: 1.2em;
  font-weight: bold;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.calendar-header-cell,
.calendar-cell {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
}

.calendar-cell.selected {
  background-color: #28a745;
  color: white;
}
.autofill-button {
  background-color: yellow;
  color: black;
  margin-left: 10px;
}
.class-selection-container {
  margin-bottom: 10px;
}

.class-selection-dropdown {
  padding: 5px;
  margin-right: 5px;
}

.autofill-button {
  background-color: yellow;
  color: black;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}
.class-selection-autofill-container {
  margin-bottom: 20px;
}

.class-selection-dropdown {
  padding: 5px;
  margin-right: 5px;
}

.autofill-button {
  background-color: yellow;
  color: black;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}
.description-textarea {
  height: 250px;
}
