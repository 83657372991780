.contact-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  /* padding-top: 80px; */
}

.contact-container h1 {
  text-align: center;
  margin-bottom: 20px;
}

.contact-container p {
  text-align: center;
  margin-bottom: 10px;
}

.social-media-links {
  text-align: center;
  margin-bottom: 20px;
}

.social-media-links a {
  margin-right: 10px;
  text-decoration: none;
  color: #007bff;
}

.contact-form form {
  display: flex;
  flex-direction: column;
}

.contact-form label {
  margin-bottom: 5px;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.contact-form button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #0056b3;
}
