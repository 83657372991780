/* AboutUs.css */

/* Center the About Us title and enhance styling */
.about-us-title {
  /* Remove conflicting properties */
  /* float: left; */
  /* margin-left: 15px !important; */
  /* position: relative; */
  /* bottom: 15px; */

  /* Add new properties */
  text-align: center;
  font-size: 3.429em;
  font-family: "Patua One", cursive;
  color: #040303;
  padding: 5px 0;
  margin-top: 40px;
  font-weight: 500;
  line-height: 1.1;
  transition: all 0.25s ease-in-out;
}
.about-section {
  margin-top: 40px;
  margin-bottom: 60px;
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;
}

.headline-container h2 {
  max-width: 600px;
  margin: 20px auto;
  font-size: 2em;
  line-height: 1.3em;
  font-family: "Patua One", cursive;
  font-weight: 500;
}

.headline-lines {
  width: 40%;
  height: 2px;
  background: #272b2d;
  margin: 20px auto;
}

.about-section p {
  font-family: "Fira Sans", sans-serif;
  font-weight: 300;
  font-size: 1.857em;
  line-height: 1.5em;
  color: #272b2d;
}

.about-section-image {
  width: 100%;
  height: auto;
  margin-top: 30px;
  max-width: 400px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
/* .our-story-section {
  padding-top: 200px;
} */
.about-section-photo-container {
  width: 100%;
  margin: 30px auto;
  display: flex;
  justify-content: center;
}

.about-section-image {
  width: 100%;
  height: auto;
  max-width: 600px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.back-to-profile-top,
.back-to-profile-bottom {
  display: flex;
  justify-content: center; /* Center the button */
  margin-bottom: 20px; /* Add spacing at the bottom */
}

.back-to-profile-top {
  margin-top: 80px; /* Adjust based on your navbar height */
}
