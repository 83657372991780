.login-container {
  max-width: 275px;
  margin-top: 120px;
  margin: 0 auto;
  padding: 20px;
  padding-top: 80px;
  text-align: center;
}

.login-container h1 {
  margin-bottom: 20px;
}

.login-container p {
  margin-bottom: 20px;
}

.separator {
  margin: 20px 0;
}

.email-signup form {
  display: flex;
  flex-direction: column;
}

.email-signup input {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.email-signup button {
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.email-signup button:hover {
  background-color: #0056b3;
}

.email-signup p {
  margin-top: 10px;
}

.social-login {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-google {
  width: 100%;
  max-width: 500px;
  display: block;
  white-space: nowrap;
}
