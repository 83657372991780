/* Container Styling */
.user-list-container {
  margin-top: 80px;
  padding: 20px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

/* Card Header Styling */
.custom-header {
  background-color: #343a40;
  color: #fff;
  padding: 20px;
}

.custom-header h2 {
  margin-bottom: 0;
}

.custom-date-time {
  font-size: 1rem;
  margin-top: 5px;
}

/* Table Styling */
.table {
  margin-bottom: 0;
}

.table thead th,
.table tbody td {
  vertical-align: middle;
  text-align: center;
}

/* Search Bar Styling */
.mb-3 input {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

/* Button Styling */
.navigation-buttons .btn {
  margin-right: 10px;
}

.print-hide {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
}

@media print {
  /* Hide elements with the class 'print-hide' when printing */
  .print-hide {
    display: none !important;
  }

  /* Hide navbar and footer when printing */
  .navbar,
  .footer {
    display: none !important;
  }

  .user-list-container {
    margin-top: 0;
    padding: 0;
  }

  .card {
    border: none;
  }

  .table {
    font-size: 12pt;
  }

  /* Ensure table headers have background color when printing */
  .table thead th {
    background-color: #ccc !important;
    -webkit-print-color-adjust: exact;
  }
}

/* Responsive Table Styling */
@media (max-width: 768px) {
  /* Hide table headers */
  .table thead {
    display: none;
  }
  /* Display each row as a block */
  .table tbody tr {
    display: block;
    margin-bottom: 1rem;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
  }
  /* Display each cell as a flex column */
  .table tbody td {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1rem;
    text-align: left;
    word-break: break-word; /* Allow long words to break and wrap */
  }
  /* Display the data label before each cell */
  .table tbody td::before {
    content: attr(data-label);
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  /* Adjust font sizes if needed */
  .table tbody td {
    font-size: 0.9rem;
  }
}
