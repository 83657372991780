/* src/components/NavBar/NavBar.css */

/* Import Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400&display=swap");

/* Base Navbar Styling */
nav {
  position: fixed;
  width: 100%;
  background-color: #000;
  top: 0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px; /* Slightly increased for better readability */
  font-family: "Fira Sans", sans-serif;
  font-weight: 400;
  z-index: 10000;
}

/* Dropdown Menu Item Hover Effect */
.dropdown-menu a:hover {
  background-color: #495057; /* Updated for better contrast with dark theme */
  color: #fff; /* Ensure text remains white on hover */
}

/* Transition Effects for Links */
.nav-links a,
.dropdown-menu a {
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Donate Button Styling */
.donate-button {
  background-color: #4d9068;
  display: flex;
  align-items: center;
  padding: 8px 12px; /* Adjusted padding for better spacing */
  border-radius: 4px;
  text-decoration: none;
  color: white;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.donate-button:hover {
  background-color: #3b7a58;
}

/* Logo Styling */
.logo {
  max-height: 50px;
  padding: 5px;
  padding-left: 20px;
}

/* Ensure nav items are right-aligned */
.navbar-nav {
  margin-left: auto;
}

/* Links inside the navbar */
.navbar-nav .nav-link {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

/* Adjust the spacing of the nav items */
.navbar-nav .nav-item {
  margin-right: 20px;
}

/* Dropdown container */
.navbar-nav .dropdown {
  position: relative;
}

/* Dropdown button */
.navbar-nav .dropdown .nav-link {
  display: block;
}

/* Dropdown content (hidden by default) */
.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #343a40; /* Dark background to match navbar */
  min-width: 160px;
  border: none;
  z-index: 1;
  border-radius: 4px;
  padding: 0;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Links inside the dropdown */
.dropdown-menu a {
  color: white; /* White text for contrast */
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Show the dropdown on hover */
.navbar-nav .nav-item:hover > .dropdown-menu {
  display: block;
}

/* Show the dropdown when active */
.dropdown.active .dropdown-menu {
  display: block;
}

/* Navbar inverse styles */
.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
  background: #000;
  border: none;
  overflow: hidden;
  border-color: #101010;
  padding-right: 15px;
  padding-left: 15px;
  overflow-x: visible;
  -webkit-overflow-scrolling: touch;
  border-top: 1px solid transparent;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
}

/* Cart Icon Styling */
.cart-icon {
  width: 24px;
  height: 24px;
  margin-right: 0; /* Remove margin to align with badge */
}

/* User Icon Styling */
.user-icon {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  border-radius: 50%; /* Make the user icon circular */
  border: 2px solid #fff; /* Add a white border for better visibility */
}

/* Heart Icon Styling */
.heart-icon {
  width: 16px;
  height: 16px;
  color: white;
  margin-left: 5px; /* Space between text and heart icon */
}

/* Cart Count Badge */
.cart-count {
  position: absolute;
  top: 5px;
  right: 10px;
  background-color: red;
  color: white;
  font-size: 10px;
  padding: 2px 5px;
  border-radius: 50%;
  transform: translate(50%, -50%);
  pointer-events: none; /* Prevent the badge from capturing mouse events */
}

/* User Information Container */
.nav-user-info {
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* User Name Styling */
.user-name {
  color: white;
  font-weight: 500;
  margin-left: 12px; /* Increased space between icon and name */
  white-space: nowrap; /* Prevent the name from wrapping */
}

/* Active Link Styling */
.navbar-nav .nav-link.active {
  color: #4d9068; /* Highlight color */
  font-weight: 600;
}

/* Responsive Styling */
@media (max-width: 992px) {
  .user-name {
    display: none; /* Hide the name on smaller screens to save space */
  }

  .navbar-nav .nav-item {
    margin-right: 10px; /* Reduce spacing on smaller screens */
  }

  .donate-button {
    padding: 6px 10px; /* Adjust padding for smaller screens */
    font-size: 12px; /* Reduce font size */
  }

  .heart-icon {
    width: 14px;
    height: 14px;
  }
}

/* Dropdown Menu Right Alignment */
.dropdown-menu-right {
  right: 0;
  left: auto;
}

/* Dropdown Item Hover Effect */
.dropdown-menu a:hover {
  background-color: #495057; /* Slightly lighter dark for hover */
  color: #fff; /* Ensure text remains white */
}

/* Dropdown Divider Styling */
.dropdown-divider {
  height: 1px;
  margin: 8px 0;
  overflow: hidden;
  background-color: #6c757d;
}

/* Dropdown Item Button Styling */
.dropdown-menu .dropdown-item {
  cursor: pointer;
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  color: white; /* Ensure text is white */
}

/* Additional Styling for Dropdown Items */
.dropdown-menu .dropdown-item:hover {
  background-color: #495057;
  color: #fff;
}

/* Logout Button Specific Styling */
.dropdown-menu .logout-button {
  color: white; /* White text */
  background-color: transparent; /* Transparent background */
}

/* Donate Button SVG Icon Alignment */
.donate-button svg {
  margin-left: 5px;
  vertical-align: middle;
}

/* Optional: Loading and Error Messages */
.user-fetch-status {
  position: absolute;
  top: 60px; /* Adjust based on navbar height */
  right: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
}

.user-fetch-status.error {
  background-color: rgba(255, 0, 0, 0.8);
}
