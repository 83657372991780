/* EventDetailsPopup.css */

.ev-detail-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.ev-detail-dialog {
  max-width: 90%;
  width: auto; /* Allows the modal to scale based on the content up to max-width */
  margin: 1.75rem auto;
}

.ev-detail-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  overflow-y: auto; /* Makes content scrollable if it exceeds max-height */
  position: relative; /* For positioning the close icon */
  max-height: 80vh;
}

.ev-detail-header,
.ev-detail-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-close-x {
  position: absolute;
  top: 10px;
  right: 15px;
  border: none;
  background: transparent;
  font-size: 24px;
  cursor: pointer;
}
/* src/components/EventDetailsPopup/EventDetailsPopup.css */

.ev-detail-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.event-modal-dialog {
  max-width: 800px;
}

.event-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 15px;
}

/* src/components/EventDetailsPopup.css */

/* Participant Count Styling */
.participant-count {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.participant-count.warning {
  color: #ffae42; /* Yellow color */
}

.participant-count.full {
  color: #ff4d4d; /* Red color */
}

/* No Space Available Message */
.no-space-message {
  color: #ff4d4d; /* Red color */
  font-weight: bold;
  margin-bottom: 10px;
}

/* Disabled Add to Cart Button Styling */
.disabled-btn {
  background-color: #6c757d !important; /* Gray background */
  cursor: not-allowed !important;
  pointer-events: none;
}

/* Optional: Tooltip Styling (if using custom tooltips) */
/* If using Bootstrap's built-in tooltips, you don't need this */

/* Participant Progress Bar Styling (Optional Enhancement) */
.participant-progress {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
}

.participant-progress .progress-bar {
  height: 20px;
  background-color: #76c7c0;
}

.event-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.btn-primary,
.btn-secondary,
.btn-danger {
  margin: 0 5px;
}

/* Responsive adjustments */
@media (min-width: 576px) {
  .ev-detail-dialog {
    max-width: 80%;
  }
}

@media (min-width: 768px) {
  .ev-detail-dialog {
    max-width: 70%;
  }
}

@media (min-width: 992px) {
  .ev-detail-dialog {
    max-width: 60%;
  }
}

@media (min-width: 1200px) {
  .ev-detail-dialog {
    max-width: 50%;
  }
}
