.donate-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  padding-top: 80px;
}

.donate-container h1,
.donation-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.donation-impact {
  list-style: none;
  padding: 0;
  margin-bottom: 40px;
}

.donation-impact li {
  background: #f9f9f9;
  padding: 10px;
  margin-bottom: 10px;
}

.donation-form {
  background: #fff;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.donation-options button {
  width: 100px;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: transparent;
  cursor: pointer;
}

.custom-amount {
  border-color: green;
}

.donation-options button:last-child {
  margin-right: 0;
}

.donation-form label {
  display: block;
  margin: 20px 0;
}

.donation-form input[type="checkbox"] {
  margin-right: 10px;
}

.next-button {
  width: 100%;
  padding: 10px;
  background-color: green;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.next-button:hover {
  background-color: darkgreen;
}

.donorbox-credit {
  text-align: center;
  margin-top: 10px;
}
.donate-message-container {
  display: flex;
  align-items: stretch;
  margin-bottom: 30px;
  min-height: 400px;
}

.donate-text {
  flex: 1;
  padding: 20px;
  background-color: #f0f0f0;
}

.donate-image {
  flex: 1;
  overflow: hidden;
}

.donate-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

@media (max-width: 768px) {
  .donate-message-container {
    flex-direction: column;
  }

  .donate-image img {
    max-width: 80%;
    margin: auto;
  }
}
