/* CustomCalendar.css */

/* Base Styles */
.rbc-month-view {
  border-color: #e3e3e3;
}

.rbc-day-bg {
  background-color: #353535;
  color: white;
  /* Set a reasonable min-height to ensure visibility */
  min-height: 50px;
}

.rbc-today {
  background: linear-gradient(135deg, #dfc8fc 0%, #5a5a5a 50%, #353535 100%);
  color: white;
}

.rbc-off-range-bg {
  background-color: #949393;
}

.rbc-selected-cell {
  background-color: #555;
  color: white;
}

.rbc-button-link {
  color: white !important;
}

.rbc-toolbar-label {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
  padding: 10px 0;
  text-align: center;
  margin-right: 200px;
}

/* Custom Event Styles */
.custom-event {
  white-space: normal; /* Allow text to wrap */
  word-break: break-word; /* Break long words if necessary */
  overflow: hidden; /* Hide overflow to maintain layout */
  text-overflow: ellipsis; /* Add ellipsis for overflowing text */
  padding: 4px 8px; /* Increased padding for better readability */
  font-size: 12px; /* Increased font size */
  line-height: 1.3; /* Increased line-height for multi-line text */
  margin-bottom: 2px; /* Minimal margin between events */
  background-color: inherit; /* Inherit background color from event style */
  border-radius: 3px; /* Slightly rounded corners for aesthetics */
  height: auto; /* Allow height to adjust based on content */
}

/* Adjust .rbc-event to reduce spacing */
.rbc-event {
  padding: 0; /* Remove padding to prevent additional spacing */
  margin: 0.5px 0; /* Minimal vertical margin */
  height: auto; /* Allow height to adjust based on content */
}

/* Adjust .rbc-event-content to ensure proper text handling */
.rbc-event-content {
  white-space: normal; /* Allow text to wrap */
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Responsive Styles */

/* Medium Devices (Tablets, 768px and below) */
@media (max-width: 768px) {
  /* Adjust toolbar label */
  .rbc-toolbar-label {
    font-size: 20px; /* Reduced font size */
    margin-right: 0;
  }

  /* Adjust headers */
  .rbc-header {
    font-size: 12px; /* Reduced font size */
    padding: 5px 2px; /* Reduced padding */
  }

  /* Adjust event font size and padding */
  .rbc-event {
    font-size: 10px; /* Adjusted font size */
    padding: 2px 4px; /* Adjusted padding */
  }

  .custom-event {
    font-size: 10px; /* Adjusted font size */
    padding: 3px 6px; /* Adjusted padding */
    line-height: 1.2; /* Adjusted line-height */
    height: auto; /* Ensure height adjusts */
  }

  /* Adjust day background min-height */
  .rbc-day-bg,
  .rbc-today,
  .rbc-off-range-bg,
  .rbc-selected-cell {
    min-height: 100px; /* Set a reasonable min-height */
  }

  /* Adjust button sizes */
  .btn {
    font-size: 12px; /* Reduced font size */
    padding: 4px 6px; /* Reduced padding */
  }

  /* Remove or adjust custom-calendar height */
  .custom-calendar {
    min-height: auto; /* Remove fixed min-height */
    height: auto;
  }
}

/* Small Devices (Mobile Phones, 480px and below) */
@media (max-width: 480px) {
  /* Adjust toolbar label */
  .rbc-toolbar-label {
    font-size: 18px; /* Further reduced font size */
  }

  /* Adjust headers */
  .rbc-header {
    font-size: 10px; /* Further reduced font size */
    padding: 4px 1px; /* Further reduced padding */
  }

  /* Adjust event font size and padding */
  .rbc-event {
    font-size: 10px; /* Maintained for readability */
    padding: 2px 4px; /* Adjusted padding */
  }

  .custom-event {
    font-size: 10px; /* Maintained for readability */
    padding: 2px 4px; /* Adjusted padding */
    line-height: 1.1; /* Adjusted line-height */
    height: auto; /* Ensure height adjusts */
  }

  /* Adjust day background min-height */
  .rbc-day-bg,
  .rbc-today,
  .rbc-off-range-bg,
  .rbc-selected-cell {
    min-height: 200px; /* Set a reasonable min-height */
  }

  /* Adjust button sizes */
  .btn {
    font-size: 10px; /* Reduced font size */
    padding: 2px 4px; /* Reduced padding */
  }

  /* Remove or adjust custom-calendar height */
  .custom-calendar {
    min-height: auto; /* Remove fixed min-height */
    height: auto;
  }
}
