.cart-page {
  padding: 4rem 4rem 4rem;
}

.cart-title {
  text-align: center;
  margin-bottom: 7rem;
}

.cart-list {
  list-style-type: none;
  padding: 0;
}
.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e1e1e1;
}

.cart-item-details {
  flex-grow: 1; /* Allows the details section to take up the available space */
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 0; /* Prevents the details from overflowing its container */
}

.cart-item-name {
  font-size: 1.2rem;
  font-weight: bold;
}

.cart-item-price {
  font-size: 1rem;
  color: #666;
}
.cart-item-image,
.cart-item-actions {
  flex-shrink: 0; /* Prevents the image and button from shrinking */
}

.remove-btn {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
}

.remove-btn:hover {
  background-color: #c82333;
}
.payment-options {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.buttons-container {
  display: flex;
  justify-content: center;
  align-items: center; /* if you also want vertical centering */
}

.paypal-button-container {
  width: 100%; /* ensure it's taking full width */
  display: block;
}

.payment-button {
  width: 1600% !important;
}

.payment-btn img {
  width: 40px;
  height: auto;
}
.payment-btn:hover {
  background-color: #f8f9fa;
  border-color: #adb5bd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
}

.payment-btn img {
  transition: transform 0.3s;
}

.payment-btn:hover img {
  transform: scale(1.3);
}
.cart-item-image {
  max-width: 120px;
  max-height: 100px;
  object-fit: cover;
  margin-right: 50px;
}
.stripe-button {
  border-collapse: collapse;
  white-space: nowrap;
  font-family: PayPalOpen-Regular, Helvetica, Arial, "Liberation Sans",
    sans-serif;
  text-transform: none;
  font-weight: 500;
  font-size: 16px;
  user-select: none;
  position: relative;
  width: 50%;
  box-sizing: border-box;
  border: none;
  vertical-align: top;
  cursor: pointer;
  overflow: hidden;
  display: inline-block;
  text-align: center;
  height: 50px;
  color: #ffffff;
  background-color: #2c2e2f;
  border-radius: 4px;
}

.stripe-button:focus,
.stripe-button:hover {
  background-color: #1b1d1f; /* Slightly darker for hover/focus, adjust as needed */
  outline: none;
}

/* Specific styling to bold the word "Stripe" */
.stripe-button .stripe-word {
  font-weight: 700;
}
