.class-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

.class-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.class-image {
  width: 100%;
  max-width: 220px;
  height: auto;
  max-height: 150px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.class-item h3 {
  margin: 10px 0 5px 0;
  font-size: 1.2em;
  text-align: center;
}

.class-item p {
  margin: 5px 0;
  font-size: 1em;
  text-align: center;
}
.classes-section {
  margin-top: 100px;
}

@media (max-width: 768px) {
  .class-container {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media (max-width: 480px) {
  .class-container {
    grid-template-columns: 1fr;
  }
}
html {
  scroll-behavior: smooth;
}
