.class-lists-container {
  margin-top: 80px;
  padding: 20px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.class-lists-title {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.my-classes-controls {
  text-align: center;
  margin-bottom: 40px; /* Increased spacing */
}

.event-item {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center; /* Center align the event info */
  align-items: center;
}

.event-info {
  font-size: 1.5rem; /* Larger font size */
  color: #555;
}

.btn {
  margin: 0 5px; /* Ensure buttons don't touch each other */
}

/* Additional styling for responsive design */
@media (max-width: 768px) {
  .my-classes-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .my-classes-controls .btn,
  .my-classes-controls .form-control {
    margin-bottom: 10px; /* Add space between controls on smaller screens */
    width: 90%; /* Full width controls for better mobile experience */
  }
}

/* Add or update these styles in your MyClasses.css */

.search-bar-container {
  margin-top: 30px; /* Space above the search bar */
  text-align: center; /* Center the search bar container */
}

.search-bar {
  width: 60%; /* Increase the size of the search bar */
  padding: 10px 15px; /* Larger padding for better text visibility */
  font-size: 1.1rem; /* Larger font size for text */
  border: 2px solid #007bff; /* Add a border with a color */
  border-radius: 25px; /* Rounded corners for the search bar */
  outline: none; /* Remove the outline to keep styling consistent */
  transition: all 0.3s ease-in-out; /* Smooth transition for focus effect */
}

.search-bar:focus {
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.8); /* Focus effect to highlight the search bar */
  border-color: #0056b3; /* Darken border color on focus */
}

/* Adjustments for responsive design */
@media (max-width: 768px) {
  .search-bar {
    width: 80%; /* Make the search bar wider on smaller screens for easier access */
  }
}
