/* ParticipationForm.css */

/* Container Styling */
.container {
  max-width: 800px; /* Increased max-width for better readability */
  margin: auto;
  padding: 20px;
  background-color: #ffffff; /* White background for a clean look */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Custom Margin Top */
.custom-margin-top {
  margin-top: 50px !important; /* Reduced margin for better spacing */
}

/* Form Row Styling */
.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Space between form groups */
  margin-bottom: 1.5rem;
}

/* Form Group Half Width */
.form-group-half {
  flex: 1 1 45%; /* Flex-grow, flex-shrink, flex-basis */
  display: flex;
  flex-direction: column;
}

/* Form Group Full Width */
.form-group-full {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
}

/* Bold Labels */
.bold-label {
  font-weight: bold;
  font-size: 1rem;
  color: #333333; /* Darker color for better contrast */
  margin-bottom: 5px;
}

/* Required Field Indicator */
.required {
  color: red;
  margin-left: 5px;
}

/* Custom Checkbox Styling */
.custom-checkbox {
  width: 20px;
  height: 20px;
  accent-color: #007bff; /* Bootstrap Primary Color */
  transform: scale(1.2); /* Enlarged for better visibility */
  margin-right: 10px; /* Spacing between checkbox and label */
}

/* Radio Button Group Styling */
.radio-group {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.radio-label {
  margin-right: 20px; /* Space between radio buttons */
  font-weight: normal; /* Regular weight for radio labels */
  color: #333333;
}

/* Form Control Styling */
.form-control {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  box-sizing: border-box;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-control:focus {
  border-color: #80bdff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Submit Button Styling */
.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  padding: 10px 20px;
  font-size: 1.1rem;
  border-radius: 4px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.btn-primary:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Section Headers */
h2 {
  color: #333333;
  /* border-bottom: 2px solid #f0f0f0; */
  padding-bottom: 5px;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

/* Paragraph Styling */
.text-justify {
  text-align: justify;
  font-size: 0.95rem;
  color: #555555;
  line-height: 1.5;
}

/* Divider Styling */
hr {
  border: none;
  height: 1px;
  background-color: #e0e0e0;
  margin: 30px 0;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }

  .form-group-half {
    flex: 1 1 100%;
  }

  .btn-primary {
    width: 100%;
    padding: 12px 0;
    font-size: 1rem;
  }

  .bold-label {
    font-size: 0.95rem;
  }

  .radio-label {
    margin-right: 10px;
  }
}
